body {
    background-image: url("https://images.unsplash.com/photo-1659853376683-28cd63ad88c7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2900&q=80");
    width: 100%;
    height: 100%;
}

.container {
    max-width: 1140px;
    align-items: center;
    background-color: #141432;
    border: 1px solid 141432;
    margin: 0 auto;
    border-radius: 20px;
}

.coin-rank ,.coin-name{
    text-align: left;
}

.heading {
    font-family: 'Poppins', sans-serif;
    color: grey;
    font-size: 12px;
    margin: 0rem 0rem;
    padding: .7rem 1rem;
    margin-top: 2rem;
    display: grid;
    grid-template-columns: .5fr .7fr .8fr 1.1fr 1.2fr 1.2fr 20px;
    justify-content: flex-end;
    text-align: end;
    border-bottom: 1px solid lightgray;
}


@media screen and (max-width:720px){
    .hide-mobile {
        display: none;
    }
}

.coin-row {
    font-family: 'Poppins', sans-serif;
    font-size: 13px;
    padding: 1.4rem 1rem;
    display: grid;
    grid-template-columns: .5fr .7fr .8fr 1.1fr 1.2fr 1.2fr 20px;
    justify-content: flex-end;
    align-items: center;
    text-align: end;
    border-bottom: 1px solid lightgray;
}

.coin-row:hover {
    background-color: #2b2b46;
    transition: 0.3s ease-in-out;
    cursor: pointer;
}

img {
    height: 40px;
    width: auto;
}

.img-symbol {
    display: flex;
    align-items: center;
}

