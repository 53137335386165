.branding {
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.coingecko-logo {
    height: 25px;
    width: 25px;
}